// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--9e5d0";
export var btn = "styles-module--btn--cf883";
export var clickWave = "styles-module--click-wave--d04a4";
export var error = "styles-module--error--3a5e0";
export var formHolder = "styles-module--formHolder--a27cc";
export var getform = "styles-module--getform--67b91";
export var mainform = "styles-module--mainform--f3541";
export var optionInput = "styles-module--option-input--a294b";
export var options = "styles-module--options--15a0a";
export var success = "styles-module--success--5cf15";