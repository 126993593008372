// extracted by mini-css-extract-plugin
export var aboutusBanner = "FlexBanner-module--aboutusBanner--baf6a";
export var bgContainer = "FlexBanner-module--bgContainer--d4017";
export var columnContainer = "FlexBanner-module--columnContainer--8943d";
export var columnContainer2 = "FlexBanner-module--columnContainer2--f7faf";
export var container = "FlexBanner-module--container--c0d7e";
export var content = "FlexBanner-module--content--b7434";
export var getStarted = "FlexBanner-module--getStarted--7dcae";
export var loginSection = "FlexBanner-module--loginSection--01f9e";
export var portalsFlex = "FlexBanner-module--portalsFlex--8fbc7";
export var portalsFlex2 = "FlexBanner-module--portalsFlex2--3b7df";
export var portalsFlex22 = "FlexBanner-module--portalsFlex22--7fd48";
export var portalsFlexLicense = "FlexBanner-module--portalsFlexLicense--be693";
export var portalsSection = "FlexBanner-module--portalsSection--92946";
export var servicesSection = "FlexBanner-module--servicesSection--64658";
export var wconnectsection = "FlexBanner-module--wconnectsection--8ce1a";