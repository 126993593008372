import React from "react";
import * as styles from "./FlexBanner.module.scss";


function FlexBanner({extraClass,title,desc,image, bgImage}){
    return(
        <div style={{backgroundImage:`url(${bgImage?bgImage:""})`}} className={`${styles.container} ${styles[extraClass]}`}>
            <div className={styles.content}>
                <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
                <div dangerouslySetInnerHTML={{ __html: desc }}></div>
            </div>
           {image &&  <img alt="banner" src={image} />}
        </div>
    );
}
export default FlexBanner;