import React, { useEffect } from "react";
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";
import GetForm from "../components/molecules/getStarted/Form";
import AOS from "aos";
import "aos/dist/aos.css";
import FlexBanner from "../components/atoms/FlexBanner/FlexBanner";
import { Helmet } from "react-helmet";
function Index() {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1500,
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Contact</title>
        <link rel="canonical" href="https://masterwizr.com/contact" />
      </Helmet>
      <Header />
      {/* <GetBanner /> */}
      <FlexBanner
        extraClass={"getStarted"}
        title={"What can we do for <span>you</span>?"}
        bgImage="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704323032/Rectangle_15235_lrgxp4.png"
        //  image="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680467170/web%20images/Usercases_3_1_1_b9ef1n.png"
      />
      <GetForm />
      <Footer />
    </>
  );
}
export default Index;
